@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&family=Nanum+Brush+Script&display=swap');



  .landing-container {
    text-align: center;
    position: relative;
    padding: 100px 20px;
    color: #3c8592e3; /* Font color */
    font-size: 1em;
  }

  .landing-title {
    /*font-family: 'Rajdhani', sans-serif;*/
    font-family: 'EB Garamond', serif;
    /*font-family: 'Nanum Brush Script', cursive;*/
    font-size: 5.5em;
    margin-bottom: 0.5em;
    margin-top: -.5em;
    text-shadow: 1px 2px #d7dadbab
  }

  .know-me-button,
  .know-me-button *,
  .know-me-button :after,
  .know-me-button :before,
  .know-me-button:after,
  .know-me-button:before {
    border: 0 solid;
    box-sizing: border-box;
  }
  .know-me-button:disabled {
    cursor: default;
  }
  .know-me-button:-moz-focusring {
    outline: auto;
  }
  .know-me-button svg {
    display: block;
    vertical-align: middle;
  }
  .know-me-button [hidden] {
    display: none;
  }
  .know-me-button {
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: button;
    background-color: rgba(60, 133, 146, 0.646);
    background-image: none;
    color: #ebe4dfdd;
    cursor: pointer;
    font-family: 'EB Garamond', serif;
    font-size: 100%;
    margin-top: 0px;
    margin-bottom: 15px;
    border-radius: 999px;
    box-sizing: border-box;
    display: inline-flex;
    font-weight: 900;
    -webkit-mask-image: none;
    padding: 1rem 2.5rem;
    position: relative;
    text-transform: uppercase;
  }
  .know-me-button:before {
    --thickness: 4px;
    border: var(--thickness) solid #3c859296;
    border-radius: 999px;
    content: "";
    inset: calc(var(--thickness) * -1);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: scale(1.3);
    transition: transform 0.2s, opacity 0.2s;
  }
  .know-me-button:hover:before {
    opacity: 1;
    transform: scale(1);
  }
  .know-me-button:hover {
    background-color: transparent;
    color: rgba(204, 228, 237, 0.939);
  }

  

  @media (max-width: 600px) {
    .landing-container {
      padding-top: 35px;
    }
    .landing-title {
      padding-top: 50px;
      font-size: 3.5em;
    }
    .know-me-button {
      font-size: 12px;
      padding: .4rem 1rem;
    }
  }