
.body-background {
  background-image: url('background3.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-attachment: fixed;
  height: 100vh; /* This will make sure your background covers the full viewport height */
}

@media (max-width: 600px) {
  .body-background{
    background-image: url('phone-background.png');
    background-attachment: scroll;
    height: -webkit-fill-available;
  }
}

