@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap');

.nav {
  display: flex
}

.navbar {
    text-align: center;
    padding: .5em 0;
    z-index: 1000;
    position:sticky;
    background-color:transparent;
    width:100%;
    padding: 8px 0px;
    background-color: transparent
  }
  
  .nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  
  .nav-item {
    margin: 0 35px;
    text-decoration: none;
    color: #65abb7e3; /* Adjust the color to fit your background image */
    font-family: 'EB Garamond', serif; /* Replace with your chosen font */
    font-size: 1.6em;
    transition: color 0.3s ease;
    cursor: pointer; 
  }
  

  
  .nav-item:hover {
    color: #d0c0b2; /* A highlight color when hovering */
  }


  .hamburger {
    position: relative;
    display: block;
    width:35px;
    cursor:pointer;
    appearance: none;
    background: none;
    outline: none;
    border: none;
    z-index: 99;
  }

  .hamburger .bar, .hamburger:after, .hamburger:before {
    content: '';
    display: block;
    width: 100%;
    height: 3.5px;
    background-color: #d0c0b2;
    padding: 0 5px;
    margin: 6px 0px;
    transition: 0.4s;
  }

  .hamburger.is-active:before {
    transform: rotate(-45deg) translate(-9.5px, 4.5px)
  }

  .hamburger.is-active:after {
    transform: rotate(45deg) translate(-8px, -4.5px)
  }

  .hamburger.is-active .bar {
    opacity: 0;
  }

  .mobile-nav {
    position: fixed;
    top:0;
    left: 100%;
    background-color: rgb(8, 46, 66);
    height: 100vh;
    width: 100%;
    display: block;
    z-index: 98;
    padding-top: 120px;
    transition: 0.4s;
  }

  .mobile-nav-list {
    list-style: none;
    padding-left: 0;
  }

  .mobile-nav.is-active {
    left:0%;
  }

  .mobile-nav li {
    display: block;
    margin: 0 auto 16px;
    text-align: center;
    width: 100%;
    max-width: 200px;
    padding: 12px 10px;
    background-color: #65abb7e3;
    color: white;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .navbar {
      display: none
    }

  }

  @media (min-width: 768px) {
    .hamburger {
      display: none
    }

    .mobile-nav {
      display: none
    }
  }