body {
    background: #d0c0b2;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 400;
}

.title {
    font-size: 4em;
    text-align: center;
    font-family: "Bebas Neue", sans-serif;
}

h3 {
    padding-top: .25em;
    color: white;

}

.vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0,0,0,0.25), 0 0.4em 1.25em 0 rgba(0,0,0,0.15) !important;
    padding: 2em 3em !important;
    background-color: rgb(40, 78, 104);
    color: white;
    font-size: 20px;
}

.date {
    color: rgb(0, 0, 0);
}

#description {
    margin: 1.2em 0 1em 0;
    font-size: 16.5px;
}

@media (max-width: 1170px) {
    .date {
        color: rgb(247, 247, 247);
    }

    .vertical-timeline-element-content {
        padding: .7em 1.3em !important;
    }
  }

  